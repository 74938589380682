/* Root variables */
:root {
  --background-color: var(--background-color);
  --navbar-text-color: var(--navbar-text-color);
}

/* Global styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: var(--background-color);
}

#root {
  height: 100vh;
}

.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

/* Navbar styles */
.navbar-dark .navbar-nav .nav-link {
  color: var(--navbar-text-color);
  margin-right: 15px;
}

.navbar-dark .navbar-nav .btn-outline-light {
  border: 2px solid white;
  border-radius: 40px;
}

.rounded-navbar {
  border-radius: 50px;
  display: flex;
  border-color: #000;
  justify-content: space-between;
}

.logo-circle {
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 90px;
  height: 90px;
}

.navbar-brand img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.navbar-brand,
.navbar-nav {
  align-items: center;
  display: flex;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,...");
}

/* Button styles */
.btn.rounded-btn {
  border-radius: 0px;
}

/* Gallery styles */
.gallery-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  padding: 20px;
}

.polaroid {
  width: 200px;
  background: white;
  box-shadow: 0 0 10px rgba(193, 193, 193, 0.5);
  padding: 10px 10px 35px 10px;
  text-align: center;
}

.polaroid:hover {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
}

.polaroid img {
  width: 100%;
  display: block;
}

.film {
  width: 200px;
  text-align: center;
}

.film img {
  width: 100%;
  display: block;
}

.film-container {
  margin: auto;
}

.film-container .row {
  margin: auto;
  padding-top: 160px;
}

.stack {
  position: absolute;
  transition: transform 0.3s ease;
  width: 200px;
}

.stack img {
  max-width: 100%;
  display: block;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}

/* Image viewer styles */
.image-viewer,
.gallery-view {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-viewer-content {
  display: flex;
  overflow-x: auto;
}

.image-viewer-content img {
  max-width: 100%;
  max-height: 80vh;
  margin: 0 10px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;
  padding: 20px;
}

.gallery-grid img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Film styles */
.film-vertical,
.film-horizontal {
  position: relative;
  overflow: hidden;
}

.film-vertical img {
  width: auto;
  height: 100%;
  max-height: 400px;
}

.film-horizontal img {
  width: 100%;
  height: auto;
  max-width: 400px;
}

.film-vertical:hover img,
.film-horizontal:hover img {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

/* About section styles */
.about-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.background-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.about-section {
  text-align: center;
}

.about-me-button {
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  margin-bottom: 10px;
  border: 2px solid transparent;
  padding: 10px 25px;
  font-size: 1.5em;
  color: #fff;
  cursor: pointer;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  text-align: center;
}

.about-me-button:hover {
  background-color: #000;
  border-color: #fff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  color: #fff;
}

/* Flip card styles */
.flip-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flip-card {
  background-color: transparent;
  width: 300px;
  height: 500px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flipped {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  backface-visibility: hidden;
}

.flip-card-front {
  color: black;
}

.flip-card-back {
  background-color: white;
  color: black;
  transform: rotateY(180deg);
}

.flip-card-back p {
  font-family: 'Swanky and Moo Moo', cursive;
}

.flip-card img {
  width: 100%;
  height: auto;
}

/* Icon styles */
.icon-circle {
  background-color: black;
  border-radius: 50%;
  padding: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.icon-circle svg {
  width: 30px;
  height: 30px;
}

/* Loading spinner styles */
.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
}

.loading-screen img {
  width: 1000px;
  height: auto;
}

/* Social icons styles */
.social-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.social-icons svg {
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease;
  margin: 5px;
}

.social-icons svg:hover {
  color: #ccc;
}

/* Footer styles */
.footer-container {
  background-color: #f4f4f4;
  color: #666;
  text-align: center;
  padding: 20px 0;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

.footer-section {
  flex: 1;
  padding: 0 20px;
}

.footer-section h5 {
  margin-bottom: 15px;
}

.footer-section p {
  text-align: left;
}

.footer-bottom {
  font-size: 14px;
}

.footer {
  padding: 50px 0;
  background-color: #f8f9fa;
}

.footer p {
  font-size: 14px;
  color: #333;
}

.social-icon {
  display: flex;
  justify-content: flex-end;
}

/* Navigation styles */
.nav-container {
  display: flex;
}

.nav-row {
  display: flex;
  flex-wrap: nowrap;
}

.nav-col {
  flex: 1;
  padding: 10px;
}

.nav-sidebar {
  max-width: 300px;
}

.nav-content {
  flex-grow: 1;
}

/* Media queries */
@media (max-width: 991px) {
  .navbar-collapse {
    margin-top: 10px;
  }
}

@media (min-width: 992px) {
  .navbar {
    flex-direction: column;
  }

  .navbar-collapse {
    flex-basis: auto;
  }
}

@media (max-width: 768px) {
  .polaroid {
    width: 110px;
    padding: 10px 10px 20px 10px;
  }

  .polaroid img {
    width: 100px;
  }

  .film {
    width: 110px;
  }

  .gallery-grid2 {
    height: 100px;
    width: 100px;
    padding-top: 30px;
    margin: auto;
  }

  .stack {
    width: 150px;
  }

  .about-container {
    background-image: transparent;
  }

  .flip-card {
    width: 40px;
    height: 60px;
  }

  .footer-content {
    flex-direction: column;
  }

  .footer-section {
    margin-bottom: 20px;
  }

  .footer-section p {
    text-align: center;
  }
}

.gallery-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.gallery-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.gallery-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.gallery-images img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.collection-description {
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  color: #4A5568;
  margin-bottom: 20px;
}
.collection-title {
  font-family: "Spectral", serif;
  font-weight: 200;
  font-style: normal;
}